<template>
<div class="flex justify-center">
    <div class="sm:mb-32 mb-64 bg-white mt-8 sm:w-frame rounded px-4 md:px-8 p-5 md:p-10 pb-12 md:pb-24 text-xs sm:text-base">
        <div class="bg-tex-14 flex p-2 md:p-4 rounded-md space-x-2 md:space-x-4 items-center mb-8">
            <div>
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.6851 34.863H33.3148C33.7473 34.863 34.1724 34.7507 34.5485 34.5372C34.9247 34.3236 35.239 34.0162 35.4607 33.6449C35.6825 33.2735 35.8041 32.851 35.8137 32.4186C35.8233 31.9862 35.7206 31.5587 35.5156 31.1779L22.2015 6.45132C21.257 4.69819 18.7429 4.69819 17.7984 6.45132L4.48432 31.1779C4.27928 31.5587 4.17655 31.9862 4.18616 32.4186C4.19578 32.851 4.31741 33.2735 4.53917 33.6449C4.76093 34.0162 5.07523 34.3236 5.45136 34.5372C5.8275 34.7507 6.25259 34.863 6.6851 34.863V34.863Z" stroke="#443200" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19.5521 15.2644L20.0005 24.7957L20.4482 15.2683C20.4509 15.2074 20.4412 15.1466 20.4197 15.0896C20.3981 15.0325 20.3652 14.9805 20.3229 14.9366C20.2805 14.8927 20.2297 14.8579 20.1735 14.8344C20.1172 14.8108 20.0568 14.799 19.9958 14.7996V14.7996C19.9359 14.8002 19.8767 14.8127 19.8218 14.8366C19.7668 14.8604 19.7172 14.895 19.6758 14.9383C19.6345 14.9817 19.6022 15.0329 19.5809 15.0889C19.5597 15.1449 19.5499 15.2046 19.5521 15.2644V15.2644Z" stroke="#443200" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20 31.0349C19.691 31.0349 19.3889 30.9433 19.1319 30.7716C18.875 30.5999 18.6747 30.3559 18.5564 30.0704C18.4382 29.7848 18.4072 29.4707 18.4675 29.1676C18.5278 28.8645 18.6766 28.5861 18.8951 28.3676C19.1137 28.149 19.3921 28.0002 19.6952 27.9399C19.9983 27.8796 20.3124 27.9106 20.5979 28.0288C20.8835 28.1471 21.1275 28.3474 21.2992 28.6043C21.4709 28.8613 21.5625 29.1634 21.5625 29.4724C21.5625 29.8868 21.3979 30.2842 21.1049 30.5773C20.8118 30.8703 20.4144 31.0349 20 31.0349Z" fill="#443200"/>
                </svg>
            </div>
            <div class="text-tex-15">
                <p class="md:text-lg font-semibold">Erreur de génération du code de recharge</p>
                <p>Le service de génération de code de recharge <br>  est indisponible</p>
            </div>
        </div>
        <div>
            <p class="text-base md:text-lg font-semibold">Chers client,</p>
            <p class="mb-4">Nous voulons vous rassurer  que votre paiement de <span class="font-semibold">{{ failedTransact.amount }} Fcfa</span> a
                bien été effectué pour la recharge du compteur 
                <span class="font-semibold"> {{ failedTransact.meterNumb }}({{ failedTransact.meterOwner }}).</span><br>
                Le code de recharge sera envoyé sur le numéro <span class="font-semibold">+{{ failedTransact.countryCode + failedTransact.phone }}</span> dès 
                que le service sera à nouveau actif.
                <br><br>
                KKiaPay SBEE vous présente ses excuses pour ce désagrément.
                <br><br>
                Si vous avez des questions, n’hésitez pas à nous contacter
            </p>
            <p class="flex items-center space-x-2">
                <span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.40275 4.93999C6.80581 4.49034 6.82786 3.94434 6.43885 3.53484C5.83527 2.89851 5.21565 2.27422 4.57799 1.67001C4.1248 1.24043 3.59542 1.21434 3.13421 1.63588C2.48251 2.23407 1.76464 2.7881 1.40971 3.64525C1.30744 4.13705 1.30744 4.43012 1.40971 4.9159C1.89097 6.70647 2.72715 8.31838 3.90222 9.74962C5.68488 11.9276 7.8385 13.5877 10.5676 14.4147C10.7662 14.4749 10.9647 14.5291 11.1632 14.5853C11.7186 14.6877 11.9272 14.6998 12.3342 14.5853C13.1945 14.2321 13.7319 13.4933 14.3435 12.855C14.7766 12.4033 14.7706 11.9115 14.3455 11.4559C13.7259 10.7894 13.0782 10.1471 12.4064 9.53483C11.9512 9.11931 11.476 9.16749 11.0148 9.59907C10.6819 9.91021 10.3551 10.2294 10.0443 10.5606C9.84976 10.7694 9.66728 10.8316 9.4066 10.679C7.67808 9.65929 6.2664 8.33042 5.30589 6.54789C5.17555 6.305 5.21164 6.13437 5.40615 5.94969C5.75305 5.6245 6.08793 5.29128 6.40275 4.93999Z" fill="#222F5A"/>
                    </svg>
                </span>
                <span>Tél: 61 15 15 61</span>
            </p>
            <p class="flex items-center space-x-2">
                <span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.25 2.49988H2.75C2.28602 2.50037 1.84119 2.68491 1.51311 3.01299C1.18503 3.34107 1.0005 3.7859 1 4.24988V11.7499C1.0005 12.2139 1.18503 12.6587 1.51311 12.9868C1.84119 13.3148 2.28602 13.4994 2.75 13.4999H13.25C13.714 13.4994 14.1588 13.3148 14.4869 12.9868C14.815 12.6587 14.9995 12.2139 15 11.7499V4.24988C14.9995 3.7859 14.815 3.34107 14.4869 3.01299C14.1588 2.68491 13.714 2.50037 13.25 2.49988ZM12.8069 5.39457L8.30687 8.89456C8.21912 8.96278 8.11115 8.99981 8 8.99981C7.88885 8.99981 7.78087 8.96278 7.69312 8.89456L3.19312 5.39457C3.14025 5.35464 3.09585 5.3046 3.06248 5.24736C3.02911 5.19013 3.00746 5.12683 2.99877 5.06115C2.99007 4.99547 2.99452 4.92871 3.01185 4.86477C3.02918 4.80082 3.05905 4.74096 3.09972 4.68865C3.14039 4.63635 3.19104 4.59265 3.24874 4.56009C3.30645 4.52754 3.37004 4.50677 3.43584 4.499C3.50163 4.49124 3.56832 4.49663 3.63201 4.51486C3.69571 4.53309 3.75515 4.56379 3.80687 4.60519L8 7.86644L12.1931 4.60519C12.298 4.52601 12.4298 4.49128 12.56 4.50852C12.6903 4.52575 12.8085 4.59357 12.8892 4.69729C12.9698 4.80102 13.0064 4.93232 12.991 5.0628C12.9756 5.19329 12.9095 5.31246 12.8069 5.39457Z" fill="#222F5A"/>
                    </svg>
                </span>
                <span>Email: <a href="mailto:support@kkiapay.me"  class="text-tex-2">support@kkiapay.me</a></span>
            </p>
        </div>
    </div>
</div>
</template>

<script>

export default {

    data() {
        return {
            
        }
    },
    
    components:{
    },
    
    computed: {
        failedTransact(){
            return this.$store.getters.getJobFailedTransact
        }
    },
    mounted() {
        localStorage.removeItem('sbeeTransaction')
    },
    destroyed() {
        const transaction = {
            amount: null,
            meterNumb: null,
            meterOwner: null,
            phone: null,
            countryCode: null
        }
        this.$store.commit('mutJobFailedTransact', transaction)
    },
}
</script>

<style scoped>

</style>