<template>
<div class="bg-lg h-full ffamily-pro px-8 sm:px-20 pt-10">
    <KJobFailedMessage/>
</div>
</template>

<script>
import KJobFailedMessage from '@/components/purchasesComponents/KJobFailedMessage'

export default {

    data() {
        return {
            
        }
    },
    
    components:{
        KJobFailedMessage
    },
    
    computed: {
    },
    mounted() {
    },
}
</script>

<style scoped>
 .bg-lg {
    background-image: url(../../assets/svg/SBEEA1.svg);
    background-repeat: no-repeat;
    background-position: left bottom  ;
    background-size: cover;
}


@media (min-width: 640px) {
  .bg-lg {
    background-image: url(../../assets/svg/dashbIcons/background2lg.svg);
    background-repeat: no-repeat;
    background-position: left bottom  ;
    background-size: cover;
  }
}
</style>